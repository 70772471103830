import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["mandatoryField"];

    connect() {
        //Lang code
        this.langCode = this.mainController.getLangCode();

        //rimozione classe errore
        this.mandatoryFieldTargets.forEach((field) => {
            field.addEventListener('focus', () => {
                if (field.type == 'radio') {
                    field.closest('.custom-radio').classList.remove('--error');
                } else {
                    field.closest('.form-field').classList.remove('--error');
                }
            });
        });
    }

    submitForm(e) {
        e.preventDefault();

        var form = this.element.querySelector('form');

        if (this.checkForm(form)) {
            this.doForm(form);
        }
    }

    checkForm(form) {
        var notice = false;

        this.mandatoryFieldTargets.forEach((field) => {
            //check input(no radio) e select
            if (field.type != 'radio' && (field.value.replace(/\s+/g, '') == '' || (field.nodeName === 'SELECT' && (field.value == null || field.value.replace(/\s+/g, '') == '0')))) {
                notice = true;
                field.closest('.form-field').classList.add('--error');
            }

            //check input radio
            if (field.type == 'radio') {
                //check newsletter privacy
                //if (field.id == "contactNewsletterSubAgree" && !field.checked) {
                //    notice = true;
                //    field.closest('.custom-radio').classList.add('--error');
                //}
                //check privacy
                if (field.id == "reservationPrivacyAgree" && !field.checked) {
                    notice = true;
                    field.closest('.custom-radio').classList.add('--error');
                }
            }
        });

        if (notice) {
            return false;
        }

        //check email
        var emailInput = form.querySelector('input[type=email][name=reservationEmail]');
        if (emailInput) {
            var trimEmail = emailInput.value.replace(/\s+/g, '');
            if (!this.mainController.validateEmail(trimEmail)) {
                emailInput.closest('.form-field').classList.add('--error');
                this.mainController.showAlert(_('Email non valida'));
                return false;
            }
        }

        return true;
    }

    doForm(form) {

        //loader
        this.mainController.pageLoading();

        const dataObj = {
            reservationName: this.element.querySelector('input[name="reservationName"]').value,
            reservationSurname: this.element.querySelector('input[name="reservationSurname"]').value,
            reservationEmail: this.element.querySelector('input[name="reservationEmail"]').value,
            reservationPhone: this.element.querySelector('input[name="reservationPhone"]').value,
            reservationPeople: this.element.querySelector('select[name="reservationPeople"]').value,
            reservationSpirit: this.element.querySelector('input[name="reservationSpirit"]:checked') ? true : false,
            reservationBeer: this.element.querySelector('input[name="reservationBeer"]:checked')  ? true : false,
            reservationMessage: this.element.querySelector('textarea[name="reservationMessage"]').value,
            newsletter: this.element.querySelector('input[name="reservationNewsletterSub"]:checked').value == "1"
        };

        fetch('/' + this.langCode + '/DoReservationRequest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                '__RequestVerificationToken': this.element.querySelector('input[name="__RequestVerificationToken"]').value
            },
            body: JSON.stringify(dataObj)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            else {
                //Error handling
                throw new Error('response not ok');
            }
        }).then((responseJson) => {
            if (responseJson.status) {

                this.mainController.pageLoaded();

                this.mainController.executeCloseReservationPopup();

                this.mainController.showSuccess(_("Richiesta inviata con successo"), _("Grazie per averci mostrato il tuo interesse. Ti contatteremo al più presto."));

                form.querySelectorAll('input[type="text"]').forEach((input) => {
                    input.value = '';
                    input.closest('div').classList.remove('has-content');
                })
                form.querySelectorAll('input[type="email"]').forEach((input) => {
                    input.value = '';
                    input.closest('div').classList.remove('has-content');
                })
                form.querySelectorAll('input[type="tel"]').forEach((input) => {
                    input.value = '';
                    input.closest('div').classList.remove('has-content');
                })
                form.querySelectorAll('select').forEach((select) => {
                    select.value = '';
                    select.closest('div').classList.remove('has-content');
                })
                form.querySelectorAll('textarea').forEach((textarea) => {
                    textarea.value = '';
                    textarea.closest('div').classList.remove('has-content');
                })
            }
            else {
                this.mainController.showAlert(responseJson.message);

                this.mainController.pageLoaded();
            }
        }).catch((error) => {
            console.log(error);

            //Error handling
            this.mainController.showAlert(_("Errore nell'invio della richiesta. Riprova più tardi"));

            this.mainController.pageLoaded();
        });
    }

    //prendo main_controller.js
    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }

}